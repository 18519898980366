
import React, { useEffect,useContext } from "react";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import LanguageContext from '../../LanguageContext';
import { GiFlatHammer } from "react-icons/gi";
import { PiArrowUpRight  } from "react-icons/pi";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { LuChevronRightCircle } from "react-icons/lu";
import Accochoice from "./Accoming";
const Minertesto = () => {
  const { translations } = useContext(LanguageContext);


  return (
      <div className="unordcontainer newakefont csx2311s" style={{marginBottom:80}} >
<div className="elementor-element elementor-element-f694779 e-con-full e-flex e-con e-parent e-lazyloaded snipcss-HDVO4 raxcd" data-id="f694779" data-element_type="container" >
    <div className="elementor-element elementor-element-030709c e-con-full e-flex e-con e-child" data-id="030709c" data-element_type="container" style={{paddingLeft:5}}>
        <div className="elementor-element elementor-element-515cb98 elementor-widget elementor-widget-heading" data-id="515cb98" data-element_type="widget" data-widget_type="heading.default" >
        
        </div>
        <Accochoice></Accochoice>
    </div>
    <div className="elementor-element elementor-element-0752ef4 e-con-full e-flex e-con e-child" data-id="0752ef4" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
        <div className="elementor-element elementor-element-c78750b e-con-full e-flex e-con e-child" data-id="c78750b" data-element_type="container">
        
        </div>
    </div>
</div>

    </div>
  );
};

export default Minertesto;


