import React, { useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

function MyBook() {
    const imageUrls = [
        '/images/Sertifika1.jpg',
        '/images/Sertifika2.jpg',
        '/images/Sertifika3.jpg',
        '/images/Sertifika4.jpg',
        '/images/Sertifika5.jpg',
        '/images/Sertifika6.jpg',
    ];

    const [dimensions, setDimensions] = useState({ width: 800, height: 500 });

    useEffect(() => {
        const updateDimensions = () => {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let newWidth = Math.min(windowWidth * 0.3); // Maksimum 800px genişlik
            let newHeight = Math.min(windowHeight * 0.8); // Maksimum 600px yükseklik

            // Eğer genişlik 1600 pikselden küçükse farklı boyutlar kullan
            if (windowWidth < 1600) {
                newWidth = windowWidth / 2; // Sayfanın üçte biri genişlik
                newHeight = windowHeight * 0.6; // %80 yüksekliği
            }

            setDimensions({ width: newWidth, height: newHeight });
        };

        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className="flip-book-container">
            <HTMLFlipBook
                width={dimensions.width}
                height={dimensions.height}
                size="stretch"
                minWidth={dimensions.width * 0.5}
                maxWidth={dimensions.width}
                minHeight={dimensions.height * 0.5}
                maxHeight={dimensions.height}
                mobileScrollSupport={true}
                showCover={true}
                singlePage={true}
            >
                {imageUrls.map((url, index) => (
                    <div key={index} className="demoPage">
                        <img src={url} alt={`Page ${index + 1}`} />
                    </div>
                ))}
            </HTMLFlipBook>
        </div>
    );
}

export default MyBook;
