import React, { useEffect, useContext, useState } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import Ourrange from '../elements/astralservice/Ourrange';
import MyBookSecond from '../elements/astralservice/Flipcertifitaces2';
import FactoryStatistics from '../elements/astralservice/factory';
import Slider from "react-slick";

function Katalog() {
  const [inView, setInView] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Ekran genişliğini al
  const { translations } = useContext(LanguageContext);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setInView(true);
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Ekran boyutu değiştiğinde windowWidth state'ini güncelle
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize); // Ekran yeniden boyutlandığında çağrılır

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Ekran genişliği 530'dan küçükse slider yüksekliği 750px, aksi halde 850px olacak
  const sliderHeight = windowWidth <= 530 ? 650 : 850;

  return (
    <>
      <SEO title="Hakkımızda" />
      <main className="page-wrapper" style={{ width: "100%" }}>
        <div>
          <HeaderTopBar />
          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
        </div>
        <div style={{ width: "100%", height: "auto", marginBottom: 40 }}>
          <div className='aboabo'>
            <span className='newakefont' style={{ fontSize: 50, fontFamily: "Oswald, sans-serif", fontWeight: 500,color:"white" }}>{translations.navfour}</span>
          </div>
        </div>

        <div className='üdsdsd2321' style={{ width: "100%", paddingBottom: 70, position: "relative" }}>
          {windowWidth <= 768 ? ( // Ekran genişliği 768px veya daha küçükse slider göster
            <div style={{ width: "100%", height: sliderHeight }}>
              <Slider {...sliderSettings}>
                <div>
                  <img src="/images/altay2020d-compressed-images-0.jpg" alt="Cert 1" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-1.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-2.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-3.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-4.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-5.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-6.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-7.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
                <div>
                  <img src="/images/altay2020d-compressed-images-8.jpg" alt="Cert 2" style={{ width: "100%", height: `${sliderHeight}px` }} />
                </div>
              </Slider>
            </div>
          ) : (
            <div style={{ position: "relative", zIndex: 1 }}>
              <MyBookSecond />
            </div>
          )}

          <div className='custom-cert-container'>
            <span style={{ fontWeight: 700 }} className='newakefont custom-span'>ALTAY STEELS</span>
            <h1 className='newakefont custom-h1'>{translations.madenkatolog}</h1>
            <p className='newakefont c211x21'>{translations.madenkatolog2}</p>
          </div>
          <div className='ccc1x21 custom-right-container'>
            <img style={{ width: 444, height: 445 }} src="/images/UNICERT-ISO27001.png" alt={`Pagexe`} />
          </div>
        </div>

        <FactoryStatistics inView={inView} />
        <Ourrange />
        <FooterFour />
      </main>
    </>
  );
}

export default Katalog;
