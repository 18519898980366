import React, { useEffect,useContext } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from '../common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import { Link } from 'react-router-dom';

function MainSteel  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
       <div className="layout-container">
      <div className="text-section newakefont">
      <div className="text-section2 " >
        <h1 className='newakefont anasayfayukarıtitle'>{translations.madenservocetoptitile}</h1>
        <p className="innertextsection2">
        {translations.madenservotitle}
        </p>
   
        <p className="innertextsection3" >
        {translations.madenservotitlesub}
         </p>
         <div className="newakefont dsadsadsa2x3232e" style={{padding:20,backgroundColor:"var(--color-primary)",color:"var(--color-white)"}}>
        <span style={{fontSize:22,color:"var(--color-white) !important"}}>
        <Link style={{color:"white"}} to="/tarihce">{translations.Buttonmore}</Link>
        </span>
        </div>
        </div>
      </div>
      <div className="video-section2x3ana">
        <video autoPlay muted loop className="full-screen-video">
          <source src="/images/video2.mp4" type="video/mp4" />
          Tarayıcınız video etiketini desteklemiyor.
        </video>
   
      </div>

    </div>


        </>
    )
}

export default MainSteel;
