
import React, { useEffect,useContext } from "react";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import LanguageContext from '../../LanguageContext';
import { GiFlatHammer } from "react-icons/gi";
import { PiArrowUpRight  } from "react-icons/pi";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const OurServiceMining = () => {
  const { translations } = useContext(LanguageContext);
  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateX(40%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 1000 },
  });
  const { ref, inView } = useInView({
    triggerOnce: true, // Sadece bir kez tetiklenmesini sağlar
    threshold: 0.2 // Elementin %20'si görünür olduğunda tetiklenir
  });


  return (
      <div className="unordcontainer" style={{paddingTop:40}}>
    <div className="aligncenterleft newakefont">
    <h1 className="newakefont ewewsd12133xz" >{translations.madenserxdara}</h1>
        <p className="innertextsectionmining2 newakefont" >
        {translations.madenserxdara2}        
        </p>
        <p className="innertextsectionmining3 newakefont">
        {translations.madenserxdara3}        
        </p>
        </div>
        <div className="card-containermining">
        <div style={{position:"relative"}}>

  <div className="cardmining">
  <div className="card-headermining">
  <h3 className="newakefont" style={{margin:0,color:"var(--color-primary)",fontSize:24,borderBottom:"1px solid white",paddingBottom:10}}>{translations.madenserxdara4}</h3>
    <p className="newakefont titxsb4311" >{translations.madenserxdara5}</p>
    <p className="newakefont descxsb4311" >{translations.madenserxdara6}</p>
    <Link to="/products-materials">
 <p className="newakefont" style={{fontWeight:300,width:"90%",margin:0,padding:0,fontSize:20,marginTop:10,color:"var(--color-primary)"}}>{translations.Buttonmore}</p>
</Link>
  </div>
  </div>
  </div>

  <div style={{position:"relative"}}>
  <div className="cardmining2">
  <div className="card-headermining2">
  <h3 className="newakefont" style={{margin:0,color:"var(--color-primary)",fontSize:24,borderBottom:"1px solid white",paddingBottom:10}}>{translations.madenserxdara7}</h3>
    <p className="newakefont titxsb4311" >{translations.madenserxdara8}</p>
    <p className="newakefont descxsb4311" >{translations.madenserxdara9}</p>
    <Link to="/products-materials">
    <p className="newakefont" style={{fontWeight:300,width:"90%",margin:0,padding:0,fontSize:20,marginTop:10,color:"var(--color-primary)"}}>{translations.Buttonmore}</p>
</Link>

  </div>
  </div>
</div>
</div>
    </div>
  );
};

export default OurServiceMining;


