import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../LanguageContext.js";
import LanguageDropdown from "./LanguageSelect.js";
import { FiArrowRight  } from "react-icons/fi";
import { position } from "stylis";
import { blue } from "@mui/material/colors";

const Nav = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const { translations } = useContext(LanguageContext);
  const handleClick = () => {
    window.location.href = '/iletisim';

  };
  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };
  return (
    <ul className="mainmenu" style={{fontWeight:300}}>
      <li className="has-droupdown newakefont">
        <ul style={{ margin: 0 }} className="navwreck">
          <Link to="/">{translations.navone}</Link>
        </ul>
      </li>
      <li className="has-droupdown newakefont">
        <Link to="#">
          {translations.navtwo}
          <ul className="submenu">
            <li>
              <Link to="/tarihce">{translations.navtwoone}</Link>
            </li>
            <li>
              <Link to="/sertifikalar">{translations.navtwotwo}</Link>
            </li>

          </ul>
        </Link>
      </li>

      <li className="has-droupdown newakefont">
        <ul className="navwreck">
          <Link to="/products-materials">{translations.navthree}</Link>
        </ul>
      </li>
      <li className="has-droupdown newakefont">
        <ul className="navwreck">
          <Link to="/catalog">{translations.navfour}</Link>
        </ul>
      </li>
   
      <li className="has-droupdown newakefont">
        <div className="btn-cont" style={{ marginTop: "-5px" }}>
          <LanguageDropdown
            language={language}
            changeLanguage={changeLanguage}
          />
        </div>
      </li>
      <li></li>
      <div className="vikart" style={{position: "absolute", bottom: "30px", left: "0px", width: "100%"}}>
        <button  onClick={handleClick} className="divra">
        <Link style={{color:"white"}} to="/iletisim">{translations.navfive}</Link>
          <FiArrowRight />
        </button>
      </div>
    </ul>
  );
};
export default Nav;
