import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Map, Source, Layer } from 'react-map-gl';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import mapboxgl from 'mapbox-gl';

const ContactInfoCustom = () => {
    useEffect(() => {
        const selector = '#root > div > div.rwt-contact-area > div:nth-child(1) > div > div > div.mapboxgl-control-container > div.mapboxgl-ctrl-bottom-right > div';
    
        const checkAndRemoveElement = () => {
          const element = document.querySelector(selector);
          if (element) {
            element.remove();
            clearInterval(intervalId);  
          }
        };
    
        const intervalId = setInterval(checkAndRemoveElement, 100);
    
        return () => {
          clearInterval(intervalId);
        };
    }, []);
    const mapRef = useRef();
    const mapboxAccessToken = 'pk.eyJ1IjoiZGFkZHlvMTkwIiwiYSI6ImNscjd3YWxvajBzenkya3Bhbzhzampka2EifQ.FPUHXXSZnVRX1kSdyPiMTg';
    const [viewState, setViewState] = useState({
        longitude: 29.45431053405557,
        latitude: 40.837195664728455,
        zoom: 15.8,
        pitch: 55,
        bearing: 200.6
    });

    const onMapLoad = useCallback((event) => {
        const map = event.target;
        const modelOrigin = [29.45431053405557, 40.837195664728455 + 0.0004];
        const modelAltitude = 0;
        const modelRotate = [Math.PI / 2, 0, 0];  // Adjust rotation as necessary
        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );
        const scaleFactor = 10;  // Scale factor for the model

        const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * scaleFactor
        };

        const customLayer = {
            id: '3d-model',
            type: 'custom',
            renderingMode: '3d',
            onAdd: function (map, gl) {
                this.camera = new THREE.PerspectiveCamera(150, window.innerWidth / window.innerHeight, 0.1, 1000);
                this.scene = new THREE.Scene();
                this.scene.add(new THREE.AmbientLight(0xffffff, 1));
                this.scene.add(new THREE.HemisphereLight(0xffeeb1, 0x080820, 1));
                const loader = new GLTFLoader();
                loader.load('https://sunum.progressytems.com/images/gallery/scene2.gltf', (gltf) => {
                    this.scene.add(gltf.scene);
                });

                this.renderer = new THREE.WebGLRenderer({
                    canvas: map.getCanvas(),
                    context: gl,
                    antialias: true
                });
                this.renderer.autoClear = false;
            },
            render: function(gl, matrix) {
                const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelTransform.rotateX);
                const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransform.rotateY);
                const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransform.rotateZ);

                const m = new THREE.Matrix4().fromArray(matrix);
                const l = new THREE.Matrix4().makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ)
                    .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
                    .multiply(rotationX)
                    .multiply(rotationY)
                    .multiply(rotationZ);

                this.camera.projectionMatrix = m.multiply(l);
                this.renderer.resetState();
                this.renderer.render(this.scene, this.camera);
            }
        };

        map.addLayer(customLayer);
    }, []);



    return (
        <div className="gallery-containerxx" style={{ width: "100%",position:"relative",zIndex:5}}>
            <Map
                ref={mapRef}
                {...viewState}
                style={{ width: "100%", height: "500px", borderRadius: 20 }}
                mapStyle="mapbox://styles/daddyo190/clvwxvebe029j01oc1cpa6dxl"
                mapboxAccessToken={mapboxAccessToken}
                onMove={evt => setViewState(evt.viewState)}
                onLoad={onMapLoad}
            >
                <Source id="mapbox-dem" type="raster-dem" url="mapbox://mapbox.mapbox-terrain-dem-v1" tileSize={512} maxzoom={14} />
                <Layer id="terrain-data" type="hillshade" source="mapbox-dem" layout={{ visibility: 'visible' }} />
            </Map>
            <div style={{
    position: "absolute",
    top: 20,
    backgroundColor: "white",
    left: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer" // El işareti ekledim
 // Gölge ekledim
}}>               <a href="https://www.google.com/maps/dir//7.+Cd.+No:4+%C4%B0n%C3%B6n%C3%BC+Mah+41400+Gebze%2FKocaeli/@40.8370227,29.4542547,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x14cb27251f9cc109:0xd553b7fb22ad791c" 
              target="_blank" rel="noopener noreferrer" className="btnn newakefont" style={{fontWeight:200}}> <span className="newakefont" style={{color:"black",fontSize:21,fontWeight:500}}>Haritada Görüntüle</span></a>
            </div>
        </div>
    );
};

export default ContactInfoCustom;
