import React, { useEffect,useContext,useState } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceFive from "../elements/service/ServiceFive";
import Elements from 'elements/gallery/Gallery';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import AstralServiceDsg from '../elements/astralservice/astralservo';
import OurServiceMining from '../elements/astralservice/OurServices';
import Ourrange from '../elements/astralservice/Ourrange';
import MyBook from '../elements/astralservice/Flipcertifitaces';
import FactoryStatistics from '../elements/astralservice/factory';
function ProductMat  ()  {
  const { translations } = useContext(LanguageContext);
  const [inView, setInView] = useState(false);
  useEffect(() => {
    // Sayfanın hangi bölümünde olduğunu kontrol etmek için kullanılır
    const handleScroll = () => {
      // Burada inView değerini true yaparak animasyonu tetikleyebilirsiniz
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) { // Değeri dilediğiniz gibi ayarlayabilirsiniz
        setInView(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="page-wrapper" style={{width:"100%"}}>
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>        
<div style={{width:"100%",height:"auto",marginBottom:40}}>
<div className="layout-containerse32322 caxdaxwtx" style={{paddingTop:40}}>
      <div className="text-sectiontit333 newakefont">
      <div className="text-sectiontitsub333 " style={{marginTop:-30}} >
        <h1 className='newakefont custom-span442'>{translations.madenservocetoptitile}</h1>
        <p className="innertextsection2sectionservice" >  
        {translations.madenproduct}
        </p>
        <ul className='newakefont cax1222xaaa' >
            <li  className='newakefont sddxadsa'>
            AISI 304 316 310 309 420 430 431 Inco Inconel Duplex 316L 317 304L Gx40CrNiSi25-20
            </li>
            <li  className='newakefont sddxadsa'>
            1.4848 1.4857 GX40NiCrSi35-26 or similar kind alloys Duplex stainless steel materials 1.4462 X2CrNiMoN22-5-3 2205 CD4MCu-N Duplex Casting CD4MCu Duplex Casting GX2CrNiMoN 22-5-3 
                </li>
            <li  className='newakefont sddxadsa'>
            1.4470 CD3MN SS2507 SS2205 CE8MN A240 UNS S32205/S31803 2.4660  1.4410 1.4507 DUPLEX 2304 1.4362 S32304 1.4062 SUPER DUPLEX
            </li>
            <li className='newakefont sddxadsa'>
            Ductile Iron Casting materials GGG40 GGG40.3 GGG50 GGG60 GGG70 GGG80
            </li>
          </ul>
   
        </div>
      </div>
      <div className='video-section333x21 ccxxx'>
        <img className='imgsection2x2111' src="/images/firsty2.jpg" alt="firsty"></img>
      </div>
    </div>
    <div className="layout-containerse3232" style={{paddingTop:40}}>
   
      <div className="video-section333x21 ccxxx">
        <img className='imgsection2x2111' src="/images/firsty3.jpg" alt="firsty"></img>
      </div>
      <div className="text-sectiontit333 newakefont" >
      <div className="text-sectiontitsub333 "  >
        <h1 className='newakefont custom-span442' >{translations.madenservocetoptitile}</h1>
        <p className="innertextsection2sectionservice" >
        {translations.madenproduct2}
        </p>
        <ul className='newakefont cax1222xaaa'  >
            <li className='newakefont sddxadsa'>
            GS38 GS42 GS45 GS52 GS60 GS70 also low and mid alloy steels 4140 25CrMo4 34Crmo4 42CrMo4</li>
            <li  className='newakefont sddxadsa'>
            8620 4340 4130 4150 1030 1040 1050 20MnCr5 GSC25 20Mn5 16Mn5</li>
            <li  className='newakefont sddxadsa'>
            Cromosil Ductile Iron Casting materials GG10 GG15 GG20 GG25 GG30 GG35</li>
            <li  className='newakefont sddxadsa'>
            Ni-Hard casting materials Ni-Hard 1 - 2 - 3 - 4 and high alloy heat, wear, impact resistant steel castings(Chromium, Nickel, Titanium, Molibden, Manganese, Vanadium or same kind alloys) 1.2379 Cromosil 1.2344 GX120MN12 CH 1.2344 1.2316 A10 GX40CrSi27            </li>
          </ul>
   
        </div>
      </div>
    </div>
                </div>
                <FactoryStatistics inView={inView} />
                                <Ourrange></Ourrange>
                <FooterFour />
            </main>
        </>
    )
}

export default ProductMat;
