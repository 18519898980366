
import React, { useEffect,useContext } from "react";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import LanguageContext from '../../LanguageContext';
import { GiFlatHammer } from "react-icons/gi";
import { PiArrowUpRight  } from "react-icons/pi";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { LuChevronRightCircle } from "react-icons/lu";

const Ourrange = () => {
  const { translations } = useContext(LanguageContext);


  return (
      <div className="unordcontainer csx2311s">

<div className="elementor-element elementor-element-ad6193b e-flex e-con-boxed e-con e-parent e-lazyloaded snipcss-aAj4P " data-id="ad6193b" data-element_type="container">
    <div className="e-con-inner x23232323232" > 
        <div className="container2323232323x">
                    <div className="elementor-image-box-wrapperxx1212"  >
                         <img  loading="lazy" src="/images/service11.png" className="image-box-wrapperxx1212imgg " alt=""/>
                        <div className="elementor-image-box-D1213SD newakefont">
                            <h4 className="elementor-image-box-title h4minour">{translations.madenser12xd}</h4>
                            <p className="elementor-image-box-description323232" style={{fontWeight:200 ,fontSize:19,margin:0}}>{translations.madenser12xdsub}</p>
                        </div>
                    </div>

            <div className="elementor-image-box-wrapperxx1212"  >
                         <img  loading="lazy"  src="/images/service22.png" className="image-box-wrapperxx1212imgg " alt=""/>
                        <div className="elementor-image-box-D1213SD newakefont">
                            <h4 className="elementor-image-box-title h4minour">{translations.madenser12xd2}</h4>
                            <p className="elementor-image-box-description323232" style={{fontWeight:200 ,fontSize:19,margin:0}}>{translations.madenser12xd2sub}</p>
                        </div>
            </div>
            <div className="elementor-image-box-wrapperxx1212"  >
                         <img  loading="lazy"  src="/images/service33.png" className="image-box-wrapperxx1212imgg" alt=""/>
                        <div className="elementor-image-box-D1213SD newakefont">
                            <h4 className="elementor-image-box-title h4minour">{translations.madenser12xd3}</h4>
                            <p className="elementor-image-box-description323232" style={{fontWeight:200 ,fontSize:19,margin:0}}>{translations.madenser12xd3sub}</p>
                        </div>
            </div>
            <div className="elementor-image-box-wrapperxx1212"  >
                         <img  loading="lazy"  src="/images/service44.png" className=" image-box-wrapperxx1212imgg" alt=""/>
                        <div className="elementor-image-box-D1213SD  newakefont">
                            <h4 className="elementor-image-box-title h4minour">{translations.madenser12xd4}</h4>
                            <p className="elementor-image-box-description323232" style={{fontWeight:200 ,fontSize:19,margin:0}}>{translations.madenser12xd4sub}</p>
                        </div>
            </div>
        
            <div className="elementor-image-box-wrapperxx1212"  >
                         <img  loading="lazy" decoding="async"  src="/images/service55.png" className="image-box-wrapperxx1212imgg" alt=""/>
                        <div className="elementor-image-box-D1213SD newakefont">
                            <h4 className="elementor-image-box-title h4minour">{translations.madenser12xd5}</h4>
                            <p className="elementor-image-box-description323232" style={{fontWeight:200 ,fontSize:19,margin:0}}>{translations.madenser12xd5sub}</p>
                        </div>
            </div>
            <div className="elementor-image-box-wrapperxx1212"  >
                         <img  src="/images/service66.png" className="image-box-wrapperxx1212imgg" alt=""/>
                        <div className="elementor-image-box-D1213SD newakefont">
                            <h4 className="elementor-image-box-title h4minour">{translations.madenser12xd6}</h4>
                            <p className="elementor-image-box-description323232" style={{fontWeight:200 ,fontSize:19,margin:0}}>{translations.madenser12xd6sub}</p>
                        </div>
            </div>
            </div>

     
      
        <div className="elementor-element elementor-element-2e834d6 e-con-full e-flex e-con e-child elementor12121xxxx1221" data-id="2e834d6" data-element_type="container">
            <div className="elementor-element elementor-element-bdd2e7e elementor-widget elementor-widget-heading newakefont elementor12121xxxx1221" data-id="bdd2e7e" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container elementor12121xxxx1221">
                    <div className="elementor-heading-title elementor-size-default" style={{fontWeight:700,color:"var(--color-primary)",fontSize:18,letterSpacing:1}}>{translations.madenser12xd7}</div>
                </div>
            </div>
            <div className="elementor-element elementor-element-07b0432 animated-slow elementor-widget elementor-widget-heading animated fadeInUp elementor12121xxxx1221" data-id="07b0432" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default" style={{width:"100%"}}>
                <div className="elementor-widget-container elementor12121xxxx1221">
                    <h3 className="elementor-heading-title elementor-size-default newakefont" style={{fontSize:45,fontWeight:500}}>{translations.madenser12xd8}</h3>
                </div>
            </div>
            <div className="elementor-element elementor-element-b149461 elementor-widget elementor-widget-text-editor" data-id="b149461" data-element_type="widget" data-widget_type="text-editor.default">
                <div className="elementor-widget-container newakefont">
                    <p  style={{fontWeight:200,fontSize:19}}>{translations.madenser12xd7nine}</p>
                </div>
            </div>
            <div className="elementor-element elementor-element-eacfb2b e-con-full e-flex e-con e-child" data-id="eacfb2b" data-element_type="container">
                <div className="elementor-element elementor-element-4f459a1 elementor-widget__width-initial elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="4f459a1" data-element_type="widget" data-widget_type="icon-list.default"  style={{width:"100%"}}>
                    <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items newakefont">
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon">
                                <LuChevronRightCircle color="var(--color-primary)"/> </span>
                                <span className="elementor-icon-list-text" style={{fontWeight:300,fontSize:19}}>{translations.madenser12xd9}</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon">
                                <LuChevronRightCircle color="var(--color-primary)"/> </span>
                                <span className="elementor-icon-list-text"  style={{fontWeight:300,fontSize:19}}>{translations.madenser12xd10}</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon">
                                <LuChevronRightCircle color="var(--color-primary)"/> </span>
                                <span className="elementor-icon-list-text"  style={{fontWeight:300,fontSize:19}}>{translations.madenser12xd11}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="elementor-element elementor-element-cb4c9b0 elementor-widget__width-initial elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="cb4c9b0" data-element_type="widget" data-widget_type="icon-list.default"  style={{width:"100%"}}>
                    <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items newakefont">
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon">
                                <LuChevronRightCircle color="var(--color-primary)"/></span>
                                <span className="elementor-icon-list-text"  style={{fontWeight:300,fontSize:19}}>{translations.madenser12xd12}</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon">
                                    <LuChevronRightCircle color="var(--color-primary)"/> </span>
                                <span className="elementor-icon-list-text"  style={{fontWeight:300,fontSize:19}}>{translations.madenser12xd13}</span>
                            </li>
                            <li className="elementor-icon-list-item">
                                <span className="elementor-icon-list-icon">
                                <LuChevronRightCircle color="var(--color-primary)"/> </span>
                                <span className="elementor-icon-list-text"  style={{fontWeight:300,fontSize:19}}>{translations.madenser12xd14}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    </div>
  );
};

export default Ourrange;


