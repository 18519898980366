import React, { useContext } from 'react';
import { AiOutlineMail } from "react-icons/ai";
import { LuMailOpen } from "react-icons/lu";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { BsPhone } from "react-icons/bs";
import { GrMapLocation } from "react-icons/gr";
import { SlLocationPin } from "react-icons/sl";
import ContactInfoCustom from '../worldmaps/contactinfo';
import LanguageContext from '../../LanguageContext';

const ContactFormBlur = () => {
    const { translations } = useContext(LanguageContext);

    // Input validasyon fonksiyonu
    const validateInput = (inputId, errorId, index) => {
        const inputElement = document.getElementById(inputId + index);
        const errorElement = document.getElementById(errorId + index);
    
        if (!inputElement || !errorElement) {
            console.error("Element not found:", inputId + index, errorId + index);
            return;
        }
    
        if (inputElement.value.trim() === '') {
            errorElement.textContent = 'This field is required';
            errorElement.style.display = 'block';
        } else {
            errorElement.textContent = '';
            errorElement.style.display = 'none';
        }
    };

    return (
        <div className="elementor-element elementor-element-8a08796 e-flex e-con-boxed e-con e-parent e-lazyloaded snipcss-HYYSS XXXXX1Q222" data-id="8a08796" data-element_type="container">
        <div className="e-con-inner">
            <div className="elementor-element elementor-element-c9dd9ce e-con-full e-flex e-con e-child" data-id="c9dd9ce" data-element_type="container">
                <div className="elementor-element elementor-element-5ba1fde e-con-full e-flex e-con e-child" data-id="5ba1fde" data-element_type="container">
                    <div className="elementor-element elementor-element-3627c95 elementor-position-left elementor-mobile-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-view-default elementor-widget elementor-widget-icon-box" data-id="3627c95" data-element_type="widget" data-widget_type="icon-box.default">
                        <div className="elementor-widget-container ">
                            <div className="elementor-icon-box-wrapper">
                                <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                    <LiaPhoneVolumeSolid color='var(--color-primary)'></LiaPhoneVolumeSolid>

                                        </span>
                                </div>
                                <div className="elementor-icon-box-content">
                                    <h4 className="elementor-icon-box-title">
                                        <span className='newakefont'> {translations.contactphone} </span>
                                    </h4>
                                    <p className="elementor-icon-box-description newakefont" style={{fontWeight:200}} > 
                                    
            <a href="tel:+902625027454" 
            target="_blank" rel="noopener noreferrer" className="btnn newakefont" style={{fontWeight:200}}>
            +90 262 502 74 54
      
            </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elementor-element elementor-element-1d199dd elementor-position-left elementor-mobile-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-view-default elementor-widget elementor-widget-icon-box" data-id="1d199dd" data-element_type="widget" data-widget_type="icon-box.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                                <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                    <LuMailOpen color='var(--color-primary)'></LuMailOpen>
                                      </span>
                                </div>
                                <div className="elementor-icon-box-content">
                                    <h4 className="elementor-icon-box-title">
                                        <span className='newakefont'> {translations.contactemail} </span>
                                    </h4>
                                    <p className="elementor-icon-box-description newakefont"><a style={{fontWeight:200}} className="newakefont" href="mailto:info@altaysteels.com"> info@altaysteels.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elementor-element elementor-element-bc08226 elementor-position-left elementor-mobile-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-view-default elementor-widget elementor-widget-icon-box" data-id="bc08226" data-element_type="widget" data-widget_type="icon-box.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                                <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                     
                                    <BsPhone color='var(--color-primary)'></BsPhone>

                                    </span>
                                </div>
                                <div className="elementor-icon-box-content">
                                    <h4 className="elementor-icon-box-title">
                                        <span className='newakefont'> {translations.contactcellphone} </span>
                                    </h4>
                                    <p className="elementor-icon-box-description newakefont" style={{fontWeight:200}}>
                                    <a href="tel:+902625027454" 
            target="_blank" rel="noopener noreferrer" className="btnn newakefont" style={{fontWeight:200}}>
           +90 544 571 94 12
      
            </a>
                                     </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elementor-element elementor-element-0b78109 elementor-position-left elementor-mobile-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-view-default elementor-widget elementor-widget-icon-box" data-id="0b78109" data-element_type="widget" data-widget_type="icon-box.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                                <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                    
                                    <SlLocationPin color='var(--color-primary)'></SlLocationPin>

                                     </span>
                                </div>
                                <div className="elementor-icon-box-content">
                                    <h4 className="elementor-icon-box-title">
                                        <span  className='newakefont'> {translations.contactadress} </span>
                                    </h4>
                                    <p className="elementor-icon-box-description newakefont" style={{fontWeight:200}}>  <a href="https://www.google.com/maps/place/%C4%B0n%C3%B6n%C3%BC+Mah,+7.+Cd.+No:4,+41400+Gebze%2FKocaeli/@40.837147,29.4540906,17z/data=!4m6!3m5!1s0x14cb27251f9cc109:0xd553b7fb22ad791c!8m2!3d40.8370227!4d29.4542547!16s%2Fg%2F11jsm08c97?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" 
              target="_blank" rel="noopener noreferrer" className="btnn newakefont" style={{fontWeight:200}}>İnönü Mah. Gebze Plastikçiler Osb 7.cadde No:4 Gebze/Kocaeli Turkey</a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-element elementor-element-f1fbfda elementor-widget elementor-widget-google_maps" data-id="f1fbfda" data-element_type="widget" data-widget_type="google_maps.default">
                    <div className="elementor-widget-container">
                        <div className="elementor-custom-embed">
                        <ContactInfoCustom></ContactInfoCustom>
                        </div>
                    </div>
                </div>
            </div>
            <div className="elementor-element elementor-element-bbe089f e-con-full e-flex e-con e-child" data-id="bbe089f" data-element_type="container">
                <div className="elementor-element elementor-element-71ac262 elementor-widget elementor-widget-heading" data-id="71ac262" data-element_type="widget" data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                        <div className="elementor-heading-title elementor-size-default newakefont">
                        
                        <h4  className="newakefont iltitle" style={{borderBottom:"1px solid green"}}> {translations.offerformtitlexd2121}</h4>
                        
                        </div>
                    </div>
                </div>
                <div className="elementor-element elementor-element-f0d4dfe animated-slow elementor-widget elementor-widget-heading animated fadeInUp" data-id="f0d4dfe" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default newakefont">{translations.offerformtitlexd2121zzzz}</h3>
                        
                    </div>
                </div>
                <div className="elementor-element elementor-element-00d7157 elementor-widget elementor-widget-rform" data-id="00d7157" data-element_type="widget" data-widget_type="rform.default">
                    <div className="elementor-widget-container">
                        <form id="rform" data-form="559">
                            <div className="require-login msg">
                                <div className="require-msg-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#FF0000" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                    </svg>
                                    <div id="style-oD5hn" className="style-oD5hn">
                                        <h5 className='newakefont'>Required Login</h5> Please Login for Submit Form.
                                    </div>
                                    <div>
                                        <a type="button" className="close-msg">Close</a>
                                    </div>
                                </div>
                            </div>
                            <div className="success-submit msg">
                                <div className="success-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#4CAF50" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                    </svg>
                                    <div id="style-Yi8Iz" className="style-Yi8Iz">
                                        <h5 className='newakefont'>Success</h5> Thank you! Form submitted successfully.
                                    </div>
                                    <div>
                                        <a type="button" className="close-msg">Close</a>
                                    </div>
                                </div>
                            </div>
                            <div data-elementor-type="wp-post" data-elementor-id="559" className="elementor elementor-559">
                                <div className="elementor-element elementor-element-1ac04ec e-con-full e-flex e-con e-parent e-lazyloaded" data-id="1ac04ec" data-element_type="container">
                                    <div className="elementor-element elementor-element-cb8d63c elementor-widget elementor-widget-text" data-id="cb8d63c" data-element_type="widget" data-widget_type="text.default">
                                        <div className="elementor-widget-container">
                                            <div className="rform-container">
                                                <div className="rform-control ">
                                                    <input name="rform-text" placeholder={translations.name} className="rform-input newakefont" id="rform-input-text-213440060" type="text" onBlur={() => validateInput('rform-input-text-', 'rform-input-err-', '213440060')} aria-invalid="false" data-val="by_char" required=""/>
                                                </div>
                                                <span role="alert" className="rform-error newakefont" id="rform-input-err-213440060">This field is required</span>
                                                <div className="rform-help-text">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-6b34617 elementor-widget elementor-widget-email" data-id="6b34617" data-element_type="widget" data-widget_type="email.default">
                                        <div className="elementor-widget-container">
                                            <div className="rform-container">
                                                <div className="rform-control ">
                                                    <input name="rform-email" placeholder={translations.mail} className="rform-input newakefont" id="rform-input-email-112412183" type="email" onBlur={() => validateInput('rform-input-text-', 'rform-input-err-', '112412183')} aria-invalid="false" data-val="by_char" required=""/>
                                                </div>
                                                <span role="alert" className="rform-error newakefont" id="rform-input-err-112412183">This field is required</span>
                                                <div className="rform-help-text">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-70fc6c8 elementor-widget elementor-widget-text" data-id="70fc6c8" data-element_type="widget" data-widget_type="text.default">
                                        <div className="elementor-widget-container">
                                            <div className="rform-container">
                                                <div className="rform-control ">
                                                    <input name="rform-text" placeholder={translations.contactformcontit}  className="rform-input newakefont" id="rform-input-text-118474440" type="text" onBlur={() => validateInput('rform-input-text-', 'rform-input-err-', '118474440')} aria-invalid="false" data-val="by_char" required=""/>
                                                </div>
                                                <span role="alert" className="rform-error newakefont" id="rform-input-err-118474440">This field is required</span>
                                                <div className="rform-help-text">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-6c7dc79 elementor-widget elementor-widget-text-area" data-id="6c7dc79" data-element_type="widget" data-widget_type="text-area.default">
                                        <div className="elementor-widget-container">
                                            <div className="rform-container">
                                                <div className="rform-control ">
                                                    <textarea name="rform-message " placeholder={translations.contactformcont} className="rform-input newakefont" id="rform-input-textarea-113761401" type="text" onBlur={() => validateInput('rform-input-text-', 'rform-input-err-', '113761401')} aria-invalid="true" data-val="by_char" required=""></textarea>
                                                </div>
                                                <div className="rform-help-text">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-4162388 elementor-widget elementor-widget-rform_button_submit" data-id="4162388" data-element_type="widget" data-widget_type="rform_button_submit.default">
                                        <div className="elementor-widget-container">
                                            <div className="rform-button-container">
                                                <button className="rform-button-submit rform-btn-fullwidth" type="button" id="rform-button-submit"> {translations.contactformbut}  <div className="loading">
                                                        <div id="loading"></div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default ContactFormBlur;
