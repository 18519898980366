import React, { useEffect,useContext } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceFive from "../elements/service/ServiceFive";
import Elements from 'elements/gallery/Gallery';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import AstralServiceDsg from '../elements/astralservice/astralservo';
import OurServiceMining from '../elements/astralservice/OurServices';
import Ourrange from '../elements/astralservice/Ourrange';
function AboutUs  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="page-wrapper" style={{width:"100%"}}>
               
  <div>
  <HeaderTopBar />
  <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
</div>        
<div style={{width:"100%",height:"auto",marginBottom:40}}>
              <div className='aboabo'><span className='newakefont' style={{fontSize:50,fontFamily:"Oswald, sans-serif",fontWeight:500,color:"white"}}>{translations.navtwoone}</span></div>
              </div>
                <div style={{width:"100%",marginTop:-50}}>
        <AstralServiceDsg></AstralServiceDsg>
        <OurServiceMining></OurServiceMining>
        <Ourrange></Ourrange>
                </div>
                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
