import React, { useEffect, useContext, useState } from 'react';
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterFour from 'common/footer/FooterFour';
import FactoryStatistics from '../elements/astralservice/factory';
import Ourrange from '../elements/astralservice/Ourrange';
import LanguageContext from '../LanguageContext';
import MyBook from '../elements/astralservice/Flipcertifitaces';

function Sertifikalar() {
  const [inView, setInView] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Ekran genişliğini al
  const { translations } = useContext(LanguageContext);

  useEffect(() => {
    // Sayfanın hangi bölümünde olduğunu kontrol etmek için kullanılır
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setInView(true);
      }
    };

    const handleResize = () => {
      // Ekran boyutu değiştiğinde windowWidth state'ini güncelle
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize); // Ekran yeniden boyutlandığında çağrılır
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <SEO title="Hakkımızda" />
      <main className="page-wrapper" style={{ width: "100%" }}>
        <div>
          <HeaderTopBar />
          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
        </div>
        <div style={{ width: "100%", height: "auto", marginBottom: 40 }}>
          <div className='aboabo'>
            <span className='newakefont your-class-name1x2121' style={{color:"white"}}>{translations.madenSERT1}</span>
          </div>
        </div>

        <div className='üdsdsd2321' style={{ width: "100%", paddingBottom: 70, position: "relative" }}>
          {windowWidth <= 768 ? ( // Ekran genişliği 768px veya daha küçükse slider göster
            <div style={{ width: "100%", height: 750 }}>

              <Slider {...sliderSettings}>
                <div>
                  <img src="/images/Sertifika1.jpg" alt="Cert 1" style={{ width: "100%", height: "750px" }} />
                </div>
                <div>
                  <img src="/images/Sertifika2.jpg" alt="Cert 2" style={{ width: "100%", height: "750px" }} />
                </div>
                <div>
                  <img src="/images/Sertifika3.jpg" alt="Cert 2" style={{ width: "100%", height: "750px" }} />
                </div>
                <div>
                  <img src="/images/Sertifika4.jpg" alt="Cert 2" style={{ width: "100%", height: "750px" }} />
                </div>
                <div>
                  <img src="/images/Sertifika5.jpg" alt="Cert 2" style={{ width: "100%", height: "750px" }} />
                </div>
                <div>
                  <img src="/images/Sertifika6.jpg" alt="Cert 2" style={{ width: "100%", height: "750px" }} />
                </div>
                {/* Diğer slider elemanlarını buraya ekleyebilirsin */}
              </Slider>
            </div>
          ) : (
            <div style={{ position: "relative", zIndex: 1 }}>
              <MyBook />
            </div>
          )}

          <div className='custom-cert-container'>
            <span style={{fontWeight:700}} className='newakefont custom-span' >ALTAY STEELS</span>
            <h1 className='newakefont  custom-h1'> {translations.madenSERT2}</h1>
          </div>
          <div  className='ccc1x21 custom-right-container' >
            <img style={{ width: 444, height: 445 }} src="/images/UNICERT-ISO27001.png" alt={`Pagexe`} />
          </div>
        </div>

        <FactoryStatistics inView={inView} />
        <div style={{marginTop:-100}}>
        <Ourrange />
        </div>
        <FooterFour />
      </main>
    </>
  );
}

export default Sertifikalar;
