import React, { useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

function MyBookSecond() {
    // Resim URL'lerini buradan değiştirebilirsiniz
    const imageUrls = [
        '/images/altay2020d-compressed-images-0.jpg',
        '/images/altay2020d-compressed-images-1.jpg',
        '/images/altay2020d-compressed-images-2.jpg',
        '/images/altay2020d-compressed-images-3.jpg',
        '/images/altay2020d-compressed-images-4.jpg',
        '/images/altay2020d-compressed-images-5.jpg',
        '/images/altay2020d-compressed-images-6.jpg',
        '/images/altay2020d-compressed-images-7.jpg',
        '/images/altay2020d-compressed-images-8.jpg',
    ];

    // Genişlik ve yükseklik state'lerini oluşturuyoruz
    const [dimensions, setDimensions] = useState({ width: 800, height: 500 });

    useEffect(() => {
        // Pencere boyutlarını al ve uygun genişlik/yükseklik ayarla
        const updateDimensions = () => {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            // İki sayfanın yan yana sığabileceği genişlik ve yükseklik hesaplama
            let newWidth = Math.min(windowWidth * 0.3 ); // Maksimum 800px genişlik
            let newHeight = Math.min(windowHeight * 0.8); // Maksimum 600px yükseklik
            if (windowWidth < 1600) {
                newWidth = windowWidth / 2; // Sayfanın üçte biri genişlik
                newHeight = windowHeight * 0.6; // %80 yüksekliği
            }

            setDimensions({ width: newWidth, height: newHeight });
        };

        // İlk renderda boyutları güncelle
        updateDimensions();

        // Pencere boyutları değiştiğinde boyutları güncelle
        window.addEventListener('resize', updateDimensions);

        // Cleanup: Event listener'ı kaldır
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className="flip-book-container">
            <HTMLFlipBook
                width={dimensions.width}
                height={dimensions.height}
                size="stretch" // Ekranı doldurmak için sayfa boyutunu esnet
                minWidth={dimensions.width * 0.5} // Minimum genişlik
                maxWidth={dimensions.width} // Maksimum genişlik
                minHeight={dimensions.height * 0.5} // Minimum yükseklik
                maxHeight={dimensions.height} // Maksimum yükseklik
                showCover={true} // Kapağı göster
                mobileScrollSupport={true} // Mobil cihazlarda kaydırma desteği
            >
                {imageUrls.map((url, index) => (
                    <div key={index} className="demoPage">
                        <img src={url} alt={`Page ${index + 1}`} />
                    </div>
                ))}
            </HTMLFlipBook>
        </div>
    );
}

export default MyBookSecond;
