
import React, { useEffect,useContext,useState } from "react";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import LanguageContext from '../../LanguageContext';
import { GiFlatHammer } from "react-icons/gi";
import { PiArrowUpRight  } from "react-icons/pi";
import CountUp from 'react-countup';
import FactoryStatistics from "./factory";
const AstralServiceDsg = () => {
  const { translations } = useContext(LanguageContext);
  const [inView, setInView] = useState(false);

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateX(40%)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 1000 },
  });

  useEffect(() => {
    // Sayfanın hangi bölümünde olduğunu kontrol etmek için kullanılır
    const handleScroll = () => {
      // Burada inView değerini true yaparak animasyonu tetikleyebilirsiniz
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) { // Değeri dilediğiniz gibi ayarlayabilirsiniz
        setInView(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
      <div className="unordcontainer">


    <div className="layout-containersec23">
      <div className="text-sectionsec23 newakefont" style={{position:"relative"}}>
 <div className="xdweqw12121">
      <img
    src="/images/d23.jpg"
    alt="First Image"
    className="imgone121"
  />
      <img
    src="/images/workerman.png"
    alt="First Image"
    className="imgone221"

  />
  </div>
  <div className="workdivx newakefont">
  <img
    src="/images/baddleıcon.png"
    alt="First Image"
    style={{
      width: "62px",
      height: "62px",
      objectFit:"contain",
  
    }}
  />
  <div  style={{display:"flex",marginTop:10}}>
    <h1 className="newakefont" style={{padding:0,margin:0,color:"white"}}>
      24
    </h1>
    <span className="newakefont" style={{color:"white"}} >
      +
    </span>
  </div>
  <span className="newakefont" style={{fontSize:25,lineHeight:"30px",fontWeight:700,marginTop:10,color:"white"}}>
  {translations.madenservoservoara5}
    </span>
  </div>
      </div>
      <div className="video-section">
      <div className="text-section3 ">
        <h1 className="newakefont ewewsd12133xz">        {translations.madenservoservotit}
</h1>
        <p className="innerservicesection2 newakefont">
        {translations.madenservoservotit2}
        </p>
        <p className="innerservicesection3 newakefont">
        {translations.madenservoservotit3}

</p>
        <div className="flexturn newakefont">
          <div className="büybüy" style={{display:"flex",flexDirection:"column"}}>
            <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
            <GiFlatHammer color="var(--color-primary)" size={30}/>
            <h1 className="newakefont" style={{fontSize:20,margin:0,padding:0,marginLeft:10}}>
            
            {translations.madenservoservotit3Sub}</h1>
            </div>
            <span style={{fontWeight:200,marginTop:20,width:"87%",fontSize:18}} >{translations.madenservoservotit3Sub2}</span>
          </div>
          <div className="büybüy" style={{display:"flex",flexDirection:"column"}}>
            <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
            <GiFlatHammer color="var(--color-primary)" size={30}/>
            <h1 className="newakefont" style={{fontSize:20,margin:0,padding:0,marginLeft:10}}>{translations.madenservoservotit3Sub3}</h1>
            </div>
            <span style={{fontWeight:200,marginTop:20,width:"87%",fontSize:18}} >{translations.madenservoservotit3Sub4}</span>
            <ul className='newakefont disp2fr'  >
            <li style={{fontSize:16}} className='newakefont'>
            {translations.madenservoservotit3Sub6}</li>
            <li style={{fontSize:16}} className='newakefont'>
            {translations.madenservoservotit3Sub7}</li>
            <li style={{fontSize:16}} className='newakefont'>
            {translations.madenservoservotit3Sub8}</li>
            <li style={{fontSize:16}} className='newakefont'>
            {translations.madenservoservotit3Sub9}</li>
          </ul>
          </div>
        </div>
        <div className="newakefont wirraxx">
        <span style={{fontSize:22}}>
        <Link style={{color:"var(--color-white)"}} to="/tarihce">{translations.Buttonmore}</Link>
        </span>
        </div>
        </div>
     
      </div>
    </div>
    <FactoryStatistics inView={inView} />
  </div>
  );
};

export default AstralServiceDsg;


