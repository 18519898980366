import React, { useState,useContext } from "react";
import { LuChevronDownCircle, LuChevronUpCircle } from "react-icons/lu";
import LanguageContext from '../../LanguageContext';

const AccordionItem = ({ title, content, isOpen, toggle }) => (
  
  <div className="accordion-item">
    <div className="accordion-header" onClick={toggle}>
      <div className="accordion-icon">
        {isOpen ? <LuChevronUpCircle color="var(--color-primary)" /> : <LuChevronDownCircle color="var(--color-primary)"/>}
      </div>
      <span>{title}</span>
    </div>
    {isOpen && (
      <div className="accordion-content newakefont">
        <p className="accoinds newakefont">{content}</p>
      </div>
    )}
  </div>
);

const Accochoice = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { translations } = useContext(LanguageContext);

  const accordionData = [
    {
      title: `${translations.madenvur4}`,
      content:
      `${translations.madenvur5}`,
    },
    {
      title: `${translations.madenvur6}`,
      content:
      `${translations.madenvur7}`,
    },
    {
      title: `${translations.madenvur8}`,
      content:
      `${translations.madenvur9}`,
    },
    {
      title: `${translations.madenvur10}`,
      content:
      `${translations.madenvur11}`,
    }
  ];

  const toggleAccordion = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null); // Aynı başlık tıklandığında kapat
    } else {
      setActiveIndex(index); // Başlığı aç
    }
  };

  return (
    <div className="accordion-container newakefont">
                    <span style={{textAlign:"left",fontWeight:700,display:"flex",marginBottom:"4px"}} className="newakefont  ewewsd12133xz " >{translations.madenvur}</span>

      <h2 className="newakefont" style={{textAlign:"left",fontWeight:700}}>{translations.madenvur2}</h2>
      {accordionData.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={index === activeIndex}
          toggle={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

export default Accochoice;
