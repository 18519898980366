import React, { useEffect,useContext } from 'react';
import CountUp from 'react-countup';
import LanguageContext from '../../LanguageContext';
const FactoryStatistics = ({ inView }) => {
  const { translations } = useContext(LanguageContext);

  return (
    <div className="backmadenciservo newakefont" >
      <div className="backmadenciservoinner" style={{backgroundColor:"#494949",paddingTop:20,paddingBottom:7}}>
        <h1
          className="newakefont xsdsds2312312w"
          style={{ width: 300, textAlign: 'left', fontSize: 50,color:"white" }}
        >
          {translations.madenservoservoara1}
        </h1>

        {/* Completed Projects */}
        <Statistic
          inView={inView}
          start={0}
          end={120}
          duration={3}
          label={translations.madenservoservoara2}
        />

        {/* Expert Workers */}
        <Statistic
          inView={inView}
          start={0}
          end={75}
          duration={3}
          label={translations.madenservoservoara3}
        />

        {/* Marketing Offices */}
        <Statistic
          inView={inView}
          start={0}
          end={30}
          duration={3}
          label={translations.madenservoservoara4}
        />

        {/* Years of Experience */}
        <Statistic
          inView={inView}
          start={0}
          end={25}
          duration={3}
          label={translations.madenservoservoara5}
          suffix="+"
        />
      </div>
    </div>
  );
};

// Statistic Component to reduce redundancy
const Statistic = ({ inView, start, end, duration, label, suffix }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',color:"white" }}>
      <div style={{ display: 'flex',color:"white" }}>
        <h1 className="newakefont" style={{ fontSize: 70, margin: 0 ,color:"white" }}>
          {inView && <CountUp start={start} end={end} duration={duration} />}
        </h1>
        <h1 className="newakefont" style={{ fontSize: 30,color:"white" }}>{suffix || '+'}</h1>
      </div>
      <h1 className="newakefont" style={{ fontSize: 25,color:"white" }}>{label}</h1>
    </div>
  );
};

export default FactoryStatistics;
